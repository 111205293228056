import React, { FC } from 'react';
import LinkButton from 'src/components/common/LinkButton';

interface ContactUsProps {}

// The form submission will be handled by netlify forms
const ContactUs: FC<ContactUsProps> = () => {
  return (
    <section className="contact-us-container">
      <header>
        <h2>Contact Us</h2>
      </header>
      <style jsx={true}>{`
        .contact-us {
          @p: .ph60, .pv38;
          max-width: 500px;
          margin: 0 auto;
        }
        .input-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .input-label {
          @p: .mb6, .black80;
        }
        .input {
          @p: .pv6, .ph10;
          width: 100%;
          border: 1px #000 solid;
          border-radius: 5px;
        }
        textarea.input {
          min-height: 100px;
          resize: vertical;
        }
        .input-wrapper + .input-wrapper {
          @p: .mt20;
        }
        .action-wrapper {
          @p: .mt20;
        }
        @media (max-width: 500px) {
          .contact-us.contact-us {
            @p: .pa20;
            margin: initial;
          }
        }
      `}</style>
      <form name="contact-us" method="post" data-netlify="true" data-netlify-recaptcha="true" className="contact-us">
        <input type="hidden" name="form-name" value="contact-us" />
        <p className="input-wrapper">
          <label className="input-label">Name</label>
          <input className="input" type="text" name="name" placeholder="What should we call you" />
        </p>
        <p className="input-wrapper">
          <label className="input-label">Email</label>
          <input className="input" name="email" type="email" placeholder="We won't spam you. promise! :)" />
        </p>
        <p className="input-wrapper">
          <label className="input-label">Message</label>
          <textarea className="input" name="message" placeholder="Whatever your heart says" />
        </p>
        <div className="input-wrapper" data-netlify-recaptcha="true" />

        <div className="action-wrapper">
          <LinkButton rounded={true}>Submit</LinkButton>
        </div>
      </form>
    </section>
  );
};

export default ContactUs;
