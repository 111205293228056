import * as React from 'react';
import App from 'src/components/App';
import ContactUs from 'src/components/ContactUs';

interface Props {
  location: any;
  history: any;
}

const ContactUsPage: React.FC<Props> = ({ history }) => {
  return (
    <App history={history}>
      <ContactUs />
    </App>
  );
};

export default ContactUsPage;
